import { Chance } from 'chance';
import mock from '../mockAdapter';

import blog1 from '/images/blog-grid/stack-principles.jpg';


import profile1 from '/images/blog-grid/rares.png';


import type { BlogGridView } from '@/types/pages/blogTypes';

const chance = new Chance();

const BlogGridData: BlogGridView[] = [
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: blog1,
        tag: 'Technical',
        date:'May 19, 2023',
        title:'Principles for an optimal stack',
        profile:profile1,
        name:'Rares Pop',
        relatedBlog:false,
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Principles for an optimal stack",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:blog1
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficiency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    // {
    //     id: chance.integer({ min: 1, max: 2000 }),
    //     image: blog2,
    //     tag: 'Digital',
    //     date:'May 19, 2023',
    //     title:'Why UX Design Matters and How it Affects Ranking',
    //     profile:profile2,
    //     name:'James Robert',
    //     relatedBlog:true,
    //     blocks:[
    //         { 
    //             type: "header",
    //             data: {
    //                 text: "Why UX Design Matters and How it Affects Ranking",
    //                },
    //         },
    //         {
    //             type: "image",
    //             data: {
    //                 file: {
    //                     image:blog2} ,
    //                 } ,
    //         },
    //         {
    //             type:"text",
    //             data: {
    //                 text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new startups"
    //             }
    //         },
    //         { 
    //             type: "header2",
    //             data: {
    //                 text: "Main Heading Points",
    //                },
    //         },
            
           
    //         {
    //             type:"paragraph",
    //             data: {
    //                 text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the"
    //             } ,
    //         },
           
    //         {
    //             type: "list",
    //             data:{
    //                 type: "unordered",
    //                 items:[
    //                     "Vivamus eu lacus scelerisque, placerat commodo lectus.",
    //                     "Etiam etanteatex porta fringilla.",
    //                     "Nullam dignissim sem eu magna aliquet, sit amet volutpat tellus"
    //                 ]
    //             }
    //         },
    //         {
    //             type: "attaches",
    //             data: {
    //                 file: {
    //                 url: "/images/blog/image-pc.jpg",
    //             } ,
    //                } ,
            
    //         },
    //         {
    //             type:"text1",
    //             data: {
    //                 text:" Unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the"
    //             }
    //         },
    //         {
    //             type: "quote",
    //             data:{
    //                 text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
    //                 url: "/images/blog/cyrus.jpg",
    //                 heading:"Cyrus George",
    //                 subHeading:"Creative Head"
    //             }
    //         },
    //         {
    //             type:"text2",
    //             data: {
    //                 text:" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly  believable making this the first true generator on the Internet. It uses a dictionary"
    //             }
    //         },
    
    //     ]
    // },
    
];

mock.onGet('/api/data/blog/BlogPosts').reply(() => {
    return [200, BlogGridData];
});

// ----------------------------------------------------------------------
mock.onPost('/api/data/blog/post').reply((config: string | any) => {
    try {
        const { title } = JSON.parse(config.data);

        const paramCase = (t: string) =>
            t
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');

        const post = BlogGridData.find((_post: BlogGridView | string | any) => paramCase(_post.title) === title);

        if (!post) {
            return [404, { message: 'Post not found' }];
        }

        return [200, { post }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});


