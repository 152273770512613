import { Chance } from 'chance';
import mock from '../mockAdapter';

import frontendEng from '/images/jobs/frontend_engineer.png'
import qaTester from '/images/jobs/qa_engineer.png'
import uiUXDesigner from '/images/jobs/uiux_designer.jpg'
import backendEng from '/images/jobs/backend_engineer.jpg'
import databaseEng from '/images/jobs/database_engineer.jpg'


import profile1 from '/images/blog-grid/rares.png';


import type { JobGridView } from '@/types/pages/jobsTypes';

const chance = new Chance();

const JobsData: JobGridView[] = [
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: frontendEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Front-end Engineer',
        yearly: 12000,
        monthly: 1000,
        weekly: 250,
        hourly: 6.25,
        description: 'Web development by implementing User Interfaces using reactive frameworks.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Develop intuitive User Interfaces by writing code based on Vue.js framework."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "Treelet's methodology follows the separation of concerns principle. Generally our developers are encouraged to reach "+
                    " a good understanding of overall systems and flows of an Application. " +
                    " However, the development of intuitive User Interfaces and positive User Experiences " +
                    " raises specific problems that are best addressed in isolation at the Front-end."
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "Modern reactive frameworks have proven effectiveness for both rapid development and " +
                    " keeping the point of diminishing returns at a good distance."
                } ,
            },
           
            {
                type:"text",
                data: {
                    text:"Key responsabilities"
                }
            },

            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Write and maintain code implementing User Interfaces (routes, components)",
                        "Integrate with systems through consumption of API-s typically HTTP Rest",
                        "Ensure compliance with design and styling guides"
                    ]
                }
            },            
                        
            {
                type:"text",
                data: {
                    text:"Requirements"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "This is an entry level position that leaves some room for a learning curve. " +
                    " It rests upon the following conditions to be met."
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Strong foundation: data structures, algorithms, modern languages, object oriented and functional programming",
                        "Javascript: Solid knowledge is a must, ES6 and Typescript syntaxes are a bonus.",
                        "Web: HTML, CSS, Responsive layouts, MCV patterns",
                        "Vue.js: Vue 3 syntax, template and script fundamentals",
                        "Data stores: Vuex or Pinia are a bonus",
                        "Tooling: Vite, Nuxt are a bonus"
                    ]
                }
            }
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: frontendEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Front-end Principal Engineer',
        yearly: 24000,
        monthly: 2000,
        weekly: 500,
        hourly: 12.50,
        description: 'Web development by implementing User Interfaces using reactive frameworks.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: frontendEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Front-end Distinguished Engineer',
        yearly: 36000,
        monthly: 3000,
        weekly: 750,
        hourly: 18.75,
        description: 'Web development by implementing User Interfaces using reactive frameworks.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: qaTester,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'QA Tester',
        yearly: 12000,
        monthly: 1000,
        weekly: 250,
        hourly: 6.25,
        description: 'Quality Assurance through functionality testing.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: qaTester,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'QA Engineer',
        yearly: 24000,
        monthly: 2000,
        weekly: 500,
        hourly: 12.50,
        description: 'Quality Assurance through automated testing.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: uiUXDesigner,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'UI/UX Principal Designer',
        yearly: 24000,
        monthly: 2000,
        weekly: 500,
        hourly: 12.50,
        description: 'Design positive User experiences through intuitive User Interfaces.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Simplicity and efficency are the core principles behind our stack architecture choices."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "At Treelet there is little room for error. Our efficiency stems from a great deal of properly designed re-usable code that integrates "+ 
                    "simple and scalable services. "
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "We don't believe in 'full stack engineering'. We have specific approaches for each end of the stack:"
                } ,
            },
           
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Front-end: MVC specific frameworks",
                        "Back-end: Data centric frameworks"
                    ]
                }
            },            
            {
                type:"text",
                data: {
                    text:"Front-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Most common front-end framework we use is Nuxt, with Vue/Vuetify UI components and Vuex/Pinia for model driven actions."
                } ,
            },
            {
                type:"text",
                data: {
                    text:"Back-end"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "Event driven orchestration between multiple services. "
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Core transactions: Postgres, PostgREST",
                        "Specialized micro-services: AWS Gateway API and DynamoDB/Timestream",
                        "AI: AWS Textract, LLM, Pytorch"
                    ]
                }
            }, 
            // {
            //     type: "quote",
            //     data:{
            //         text:"We are a dedicated team of passionate product managers,developers, UX/UI designers, QA engineers experts helping businesses from new  startups ",
            //         url: "/images/blog/cyrus.jpg",
            //         heading:"Cyrus George",
            //         subHeading:"Creative Head"
            //     }
            // },
            
    
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: backendEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Back-end Engineer',
        yearly: 24000,
        monthly: 2000,
        weekly: 500,
        hourly: 12.50,
        description: 'Develop backend-end services and ensure seamles service integration.',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Develop intuitive User Interfaces by writing code based on Vue.js framework."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "Treelet's methodology follows the separation of concerns principle. Generally our developers are encouraged to reach "+
                    " a good understanding of overall systems and flows of an Application. " +
                    " However, the development of intuitive User Interfaces and positive User Experiences " +
                    " raises specific problems that are best addressed in isolation at the Front-end."
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "Modern reactive frameworks have proven effectiveness for both rapid development and " +
                    " keeping the point of diminishing returns at a good distance."
                } ,
            },
           
            {
                type:"text",
                data: {
                    text:"Key responsabilities"
                }
            },

            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Write and maintain code implementing User Interfaces (routes, components)",
                        "Integrate with systems through consumption of API-s typically HTTP Rest",
                        "Ensure compliance with design and styling guides"
                    ]
                }
            },            
                        
            {
                type:"text",
                data: {
                    text:"Requirements"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "This is an entry level position that leaves some room for a learning curve. " +
                    " It rests upon the following conditions to be met."
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Strong foundation: data structures, algorithms, modern languages, object oriented and functional programming",
                        "Javascript: Solid knowledge is a must, ES6 and Typescript syntaxes are a bonus.",
                        "Web: HTML, CSS, Responsive layouts, MCV patterns",
                        "Vue.js: Vue 3 syntax, template and script fundamentals",
                        "Data stores: Vuex or Pinia are a bonus",
                        "Tooling: Vite, Nuxt are a bonus"
                    ]
                }
            }
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: databaseEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Database Engineer',
        yearly: 12000,
        monthly: 1000,
        weekly: 250,
        hourly: 6.25,
        description: 'Database Engineering to support data store and retrieve functionality. ',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Develop intuitive User Interfaces by writing code based on Vue.js framework."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "Treelet's methodology follows the separation of concerns principle. Generally our developers are encouraged to reach "+
                    " a good understanding of overall systems and flows of an Application. " +
                    " However, the development of intuitive User Interfaces and positive User Experiences " +
                    " raises specific problems that are best addressed in isolation at the Front-end."
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "Modern reactive frameworks have proven effectiveness for both rapid development and " +
                    " keeping the point of diminishing returns at a good distance."
                } ,
            },
           
            {
                type:"text",
                data: {
                    text:"Key responsabilities"
                }
            },

            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Write and maintain code implementing User Interfaces (routes, components)",
                        "Integrate with systems through consumption of API-s typically HTTP Rest",
                        "Ensure compliance with design and styling guides"
                    ]
                }
            },            
                        
            {
                type:"text",
                data: {
                    text:"Requirements"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "This is an entry level position that leaves some room for a learning curve. " +
                    " It rests upon the following conditions to be met."
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Strong foundation: data structures, algorithms, modern languages, object oriented and functional programming",
                        "Javascript: Solid knowledge is a must, ES6 and Typescript syntaxes are a bonus.",
                        "Web: HTML, CSS, Responsive layouts, MCV patterns",
                        "Vue.js: Vue 3 syntax, template and script fundamentals",
                        "Data stores: Vuex or Pinia are a bonus",
                        "Tooling: Vite, Nuxt are a bonus"
                    ]
                }
            }
        ]
    },
    {
        id: chance.integer({ min: 1, max: 2000 }),
        image: databaseEng,
        tag: 'Engineering',
        date:'June 8, 2024',
        title:'Database Principal Engineer',
        yearly: 24000,
        monthly: 2000,
        weekly: 500,
        hourly: 12.50,
        description: 'Database Design and Development to support data store and retrieve functionality. ',
        blocks:[
            { 
                type: "header",
                data: {
                    text: "Front-end Engineer",
                   },
            },
            {
                type: "image",
                data: {
                    file: {
                        image:frontendEng
                        } ,
                    } ,
            },
            {
                type:"text",
                data: {
                    text:"Develop intuitive User Interfaces by writing code based on Vue.js framework."
                }
            },
           
            {
                type:"paragraph",
                data: {
                    text: "Treelet's methodology follows the separation of concerns principle. Generally our developers are encouraged to reach "+
                    " a good understanding of overall systems and flows of an Application. " +
                    " However, the development of intuitive User Interfaces and positive User Experiences " +
                    " raises specific problems that are best addressed in isolation at the Front-end."
                } ,
            },
            {
                type:"paragraph",
                data: {
                    text: "Modern reactive frameworks have proven effectiveness for both rapid development and " +
                    " keeping the point of diminishing returns at a good distance."
                } ,
            },
           
            {
                type:"text",
                data: {
                    text:"Key responsabilities"
                }
            },

            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Write and maintain code implementing User Interfaces (routes, components)",
                        "Integrate with systems through consumption of API-s typically HTTP Rest",
                        "Ensure compliance with design and styling guides"
                    ]
                }
            },            
                        
            {
                type:"text",
                data: {
                    text:"Requirements"
                }
            },
            {
                type:"paragraph",
                data: {
                    text: "This is an entry level position that leaves some room for a learning curve. " +
                    " It rests upon the following conditions to be met."
                } ,
            },
            {
                type: "list",
                data:{
                    type: "unordered",
                    items:[
                        "Strong foundation: data structures, algorithms, modern languages, object oriented and functional programming",
                        "Javascript: Solid knowledge is a must, ES6 and Typescript syntaxes are a bonus.",
                        "Web: HTML, CSS, Responsive layouts, MCV patterns",
                        "Vue.js: Vue 3 syntax, template and script fundamentals",
                        "Data stores: Vuex or Pinia are a bonus",
                        "Tooling: Vite, Nuxt are a bonus"
                    ]
                }
            }
        ]
    },
    
];



mock.onGet('/api/data/jobs/JobPosts').reply(() => {
    return [200, JobsData];
});

// ----------------------------------------------------------------------
mock.onPost('/api/data/jobs/post').reply((config: string | any) => {
    try {
        const { title } = JSON.parse(config.data);

        const paramCase = (t: string) =>
            t
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');

        const post = JobsData.find((_post: BlogGridView | string | any) => paramCase(_post.title) === title);

        if (!post) {
            return [404, { message: 'Post not found' }];
        }

        return [200, { post }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});


