import mock from './mockAdapter';

// import './header/Menu';
import './pages/about';
import  './pages/blogData';
import  './pages/jobsData';
import './footer/index';
import './footer/ConferenceFooter';
import './demos/itpage';

mock.onAny().passThrough();
