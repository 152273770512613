export default {
  "footer-it-footer": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/footer/ItFooter.vue").then(m => m.default || m),
  "footer-nft-footer": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/footer/nftFooter.vue").then(m => m.default || m),
  "header-color-header": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/header/ColorHeader.vue").then(m => m.default || m),
  "header-nav-collapse-mobile-nav-collapse": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/header/NavCollapse/MobileNavCollapse.vue").then(m => m.default || m),
  "header-nav-collapse": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/header/NavCollapse/NavCollapse.vue").then(m => m.default || m),
  "header-nav-group": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/header/NavGroup/index.vue").then(m => m.default || m),
  "header-nav-item": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/header/NavItem/index.vue").then(m => m.default || m),
  "logo-blue-white-logo": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/logo/BlueWhiteLogo.vue").then(m => m.default || m),
  "logo-white-logo-icon": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/logo/WhiteLogoIcon.vue").then(m => m.default || m),
  logo: () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/logo/logo.vue").then(m => m.default || m),
  "shared-auth-login": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/shared/auth/Login.vue").then(m => m.default || m),
  "shared-auth-register": () => import("/Users/ra/code/treelet/treelet-website-ui/layouts/shared/auth/Register.vue").then(m => m.default || m)
}