import { default as indexsKNVK1Jcw4Meta } from "/Users/ra/code/treelet/treelet-website-ui/pages/about-us/index.vue?macro=true";
import { default as indexpPbgz5QSZ1Meta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/add/index.vue?macro=true";
import { default as _91slug_93qpSrv3QrsCMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/article/[slug].vue?macro=true";
import { default as _91slug_93tRHAfR7RdqMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/define/[slug].vue?macro=true";
import { default as _91slug_93WKP3GR4VoHMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/edit/[slug].vue?macro=true";
import { default as indexIuUNZjVoMkMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/index.vue?macro=true";
import { default as _91slug_938dTfK94sA9Meta } from "/Users/ra/code/treelet/treelet-website-ui/pages/blog/preview/[slug].vue?macro=true";
import { default as developersCP0Djx4I1kMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/developers.vue?macro=true";
import { default as foundersjhDVb9DC4MMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/founders.vue?macro=true";
import { default as indexnnTrxKnbovMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/index.vue?macro=true";
import { default as investorsqzZnEDYO5nMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/investors.vue?macro=true";
import { default as indexi3C6wg161AMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/jobs/index.vue?macro=true";
import { default as _91id_93wWLkfOrqcRMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/jobs/position/[id].vue?macro=true";
import { default as _91_91position_93_93Ub5nD4FcZnMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/registration/[[type]]/[[position]].vue?macro=true";
import { default as indexd84Te4qWHBMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/registration/[[type]]/index.vue?macro=true";
import { default as workersDqJghqE5ipMeta } from "/Users/ra/code/treelet/treelet-website-ui/pages/workers.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-add",
    path: "/blog/add",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/add/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-article-slug",
    path: "/blog/article/:slug()",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-define-slug",
    path: "/blog/define/:slug()",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/define/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-edit-slug",
    path: "/blog/edit/:slug()",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/edit/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-preview-slug",
    path: "/blog/preview/:slug()",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/blog/preview/[slug].vue").then(m => m.default || m)
  },
  {
    name: "developers",
    path: "/developers",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "founders",
    path: "/founders",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/founders.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investors",
    path: "/investors",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/investors.vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-position-id",
    path: "/jobs/position/:id()",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/jobs/position/[id].vue").then(m => m.default || m)
  },
  {
    name: "registration-type-position",
    path: "/registration/:type?/:position?",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/registration/[[type]]/[[position]].vue").then(m => m.default || m)
  },
  {
    name: "registration-type",
    path: "/registration/:type?",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/registration/[[type]]/index.vue").then(m => m.default || m)
  },
  {
    name: "workers",
    path: "/workers",
    component: () => import("/Users/ra/code/treelet/treelet-website-ui/pages/workers.vue").then(m => m.default || m)
  }
]